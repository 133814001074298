<template>
  <div class="error_404">
    <h1 class="text-center" style="padding: 50px 0">404 访问页面不存在</h1>
    <div class="getoIndexBtn" @click="goIndex">返回首页</div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const goIndex = () => {
  router.push({
    path: '/index'
  })
}
</script>
<style lang="scss" scoped>
.error_404 {
  background: url('../../assets/image/404.png') no-repeat center center;
  width: 100vw;
  height: 100vh;
  .getoIndexBtn {
    width: 200px;
    height: 3rem;
    position: absolute;
    text-align: center;
    background: #293e73;
    color: #fff;
    font-weight: 900;
    line-height: 3rem;
    border-radius: 92px;
    top: calc(50% - 3rem);
    left: calc(50% - 100px);
  }
}
</style>
