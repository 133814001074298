import request from '@/utils/request'

// 获取项目（分类）列表
export function getProjectList(params) {
  return request({
    url: '/api/pc/course/category/list',
    method: 'get',
    params
  })
}

// 获取课程列表
export function getCourseList(params) {
  return request({
    url: '/api/pc/course/info/list',
    method: 'get',
    params
  })
}
// 获取课程详情
export function getCourseDetail(data) {
  return request({
    url: '/api/pc/course/info/' + data,
    method: 'get'
  })
}
// 获取课程章节列表
export function getChapterList(params) {
  return request({
    url: '/api/pc/course/chapter/list',
    method: 'get',
    params
  })
}
// 获取课时列表
export function getChapterPeriodList(params) {
  return request({
    url: '/api/pc/course/chapterPeriod/list',
    method: 'get',
    params
  })
}
// 用户新增收藏课程记录
export function addCollect(data) {
  return request({
    url: '/api/userCollection/add',
    method: 'POST',
    data
  })
}
// 添加课程
export function addCourseLog(data) {
  return request({
    url: '/api/link/course/addLog',
    method: 'POST',
    data
  })
}
// 用户获取课程精华问题列表
export function getProblemList(params) {
  return request({
    url: '/api/user/course/orProblem',
    method: 'get',
    params
  })
}
