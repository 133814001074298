// 一些全局的正则验证
// 方便后期更换
// ------------------------------------------------------------------------------------------------------
// 数字
export const numBer = /^[0-9]*$/
// 手机号:
export const regexPhone =
  /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
// Email地址：
export const regexEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
// 域名：
export const regexDomain =
  /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
// 身份证号(15位、18位数字)，最后一位是校验位，可能为数字或字符X：
export const regexIDNumber = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
// 密码(以字母开头，长度在6~18之间，只能包含字母、数字和特殊符号)：
export const regexCryptogram = /^(?=.*[a-zA-Z])(?=.*[1-9])(?=.*[\W]).{6,16}$/
// 强密码(必须包含大小写字母和数字的组合，不能使用特殊字符，长度在 8-10 之间)：
export const regexStrongCryptogram =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,10}$/
// 中文字符的正则表达式：
export const regexChinas = /[\u4e00-\u9fa5]/gi
// IPv4地址：
export const regexIP4 =
  /((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}/
