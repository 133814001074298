<template>
    <el-container class="learnVideo">
      <!-- 主要内容 -->
      <el-main>
        <!-- 头 -->
        <el-header :height="layout.height">
           <a>
          <div class="back" @click="goback" style="color: #fff">
            <el-icon color="#fff">
              <back />
            </el-icon>
           
            <span style="margin-left: 10px;">返回个人中心</span>
          </div>
            </a>
          <div class="title">{{ pageTitle }}</div>
        </el-header>
        <div id="videoPlayer" ref="video"></div>
      </el-main>
      <!-- 右侧 -->
      <el-aside :width="layout.width">
        <el-tabs type="card" @tab-click="switchover" >
          <!-- 目录 -->
          <el-tab-pane label="目录" style="color: #fff">
            <div style="text-align: center">课程名称</div>
            <el-collapse v-model="activeNames">
              <el-collapse-item
                v-for="items in curriculum"
                :key="items.id"
                :name="String(items.id)"
              >
                <template #title>
                  <div class="row w-100" style="justify-content: space-between">
                    <div style="max-width: 80%" class="omit">
                      {{ items.chapterName }}
                      <!-- {{ items.id }} -->
                    </div>
                    <div>
                      {{
                        ((items?.periodStudy || 0) / (items?.periodTotal || 0)) *
                          100 <
                        100
                          ? (
                              ((items?.periodStudy || 0) /
                                (items?.periodTotal || 0)) *
                              100
                            ).toFixed(2)
                          : 100
                      }}%
                    </div>
                  </div>
                </template>
  
                <div
                  class="section row"
                  v-for="item in items.children"
                  :key="item.id"
                  @click="qutVidelUrl(item)"
                >
                  <el-progress
                    type="circle"
                    :width="28"
                    :show-text="false"
                    :percentage="percentage(item)
                    "
                  />
                  <div
                    class="sectionTitle omit pointer"
                    :style="{ color: newHour.id == item.id ? '#20a0ff' : '' }"
                  >
                    {{ item.periodName }}
                  </div>
                  <!-- <div style="size: 12px">
                    {{ item.showtime }}
                  </div> -->
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-tab-pane>
          <!-- 笔记 -->
          <el-tab-pane label="笔记">
            <div style="padding: 20px">
              <editor
                title="1"
                :detail="detail"
                :time="videotime"
                :notetype="notetype"
                :isClick="isClick"
                @addEnd="addEndType"
              ></editor>
            </div>
          </el-tab-pane>
          <!-- 答疑 -->
          <el-tab-pane label="答疑">
            <div class="col alc">
              <div class="answerQuestions">
                <div class="showMessage">
                  <div v-for="item in msgList" :key="item.id">
                    <div
                      class="title"
                      :style="{ color: item.proType ? '#f00' : '' }"
                    >
                      {{ item.proType ? 'Q：' : 'A：' }}
                    </div>
                    <div
                      style="word-break: break-all"
                      v-html="item.content"
                    ></div>
                    <div class="jue subtitle aic row" style="color: #6666669e;">
                      {{ item.createTime }}
                      <!-- <span style="margin-left: 10px;" v-if="item.duration">
                        <el-icon size="20" color="#293E73"
                          ><video-camera-filled /></el-icon
                        >
                        {{
                          moment
                            .duration(item.duration || 0, 'seconds')
                            .format('HH:mm:ss')
                        }}
                      </span> -->
                      <el-button v-if="item.duration" size="small" style="margin-left: 10px;"  :icon="CaretRight"  round>{{
                        moment
                          .duration(item.duration || 0, 'seconds')
                          .format('HH:mm:ss')
                      }}</el-button>
                    </div>
                  </div>
                </div>
                
              </div>
              <div style="margin: 0 20px;">
              <el-input
                class="problemInput"
                v-model="problemValue"
                :autosize="{
                  minRows: 4,
                  maxRows: 4
                }"
                :show-word-limit="true"
                type="textarea"
                placeholder="请输入问题..."
              />
              </div>
              <div @click="useraddQuestion" class="button ma-to-20">提交</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-aside>
    </el-container>
    <reg-image ref="regImg" @close="closeReg"></reg-image>
  </template>
  <script setup>
  import { ref, watch, onBeforeUnmount } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import momentDurationFormatSetup from 'moment-duration-format'
  // 图标
  import { VideoCameraFilled, Back , CaretRight } from '@element-plus/icons'
  // 文档
  import Editor from './components/Editor.vue'
  import regImage from './components/regImage/image'
  // 视频
  import Player from 'xgplayer/dist/simple_player'
  import volume from 'xgplayer/dist/controls/volume'
  import playbackRate from 'xgplayer/dist/controls/playbackRate'
  import pip from 'xgplayer/dist/controls/pip'
  import flex from 'xgplayer/dist/controls/flex'
  import '@/.xgplayer/skin/index.js'
  // 方法和接口
  import { getItem } from '../../../utils/storage'
  import {
    getChapterList,
    getChapterPeriodList,
    addCourseLog
  } from '@/api/curriculum'
  import {
    getNoteList,
    addQuestion,
    getQuizDetail,
    getQuizList
  } from '../../../api/user'
  momentDurationFormatSetup(moment)
  // 公共参数
  const router = useRouter()
  const store = useStore()
  const urlParams = getItem('videoInfo')
  const curriculum = ref([])
  // 消息相关
  const isClick = ref(true)
  const msgList = ref([])
  // 视频相关
  var player = {}
  const oldPlayInfo = ref({})
  const videotime = ref(0)
  const activeNames = ref('0')
  var isloveend = 0
  const addvideoLog = ref({}) // 视频学习记录
  // 笔记相关
  const notetype = ref('add')
  // 页面相关
  const layout = ref({ width: '400px', height: '80px' })
  const pageTitle = ref('')
  const pageOpenTime = ref(1)
  // 答疑相关
  const problemValue = ref('')
  const detail = ref({})
  // nowaday = N 当前的信息
  const newChapter = ref({})
  const newHour = ref({})
  const regImg = ref(null)
  const who = ref([])
  // 根据课程获取章节目录列表
  getChapterList({
    courseId: urlParams.courseId,
    userNo: store.getters.userInfo.userId
  }).then(async (res) => {
    curriculum.value = res?.rows || res
    const section = await getChapterPeriodList({
      courseId: urlParams.courseId,
      userNo: store.getters.userInfo.userId
    })
    curriculum.value.forEach((item, indexs) => {
      var chi = []
      section.forEach((itemChildren) => {
        if (itemChildren.chapterId === item.id) {
          // let time = moment.duration(itemChildren.duration, 'seconds') //得到一个对象，里面有对应的时分秒等时间对象值
          // let hours = time.hours()
          // let minutes = time.minutes()
          // let seconds = time.seconds()
          // if (hours) {
          //   itemChildren.showtime = moment({
          //     h: hours,
          //     m: minutes,
          //     s: seconds
          //   }).format('HH时mm分ss秒')
          // } else if (minutes) {
          //   itemChildren.showtime = moment({
          //     m: minutes,
          //     s: seconds
          //   }).format('mm分ss秒')
          // } else {
          //   itemChildren.showtime = moment({
          //     s: seconds
          //   }).format('ss秒')
          // }
          chi.push(itemChildren)
        }
      })
      item.children = chi
      if (!urlParams.currentClass) {
        // 没有观看记录，初始化为第一个课时
        if (!indexs) {
          who.value = [indexs, 0]
          newChapter.value = item
          newHour.value = item.children[0]
          oldPlayInfo.value = item.children[0]
          pageTitle.value = item.children[0].periodName
          onMounted();
        }
      }
      
      if (urlParams.currentClass&&urlParams.chapterId==item.id) {
        // 拥有观看记录
        var temchapter = 0;
        var temtimes = 0;
        section.forEach((itemchidren, index) => {
          if(itemchidren.chapterId!=temchapter){
            temchapter = itemchidren.chapterId;
            temtimes=0;
          }
          if (itemchidren.id === urlParams.currentClass) {
            who.value = [indexs, temtimes]
            newChapter.value = item
            newHour.value = itemchidren
            oldPlayInfo.value = itemchidren
            pageTitle.value = itemchidren.periodName
                    onMounted();
          }
          temtimes++;
        })
      }
    })
    activeNames.value = String(newChapter.value.id)
  })
  
  // 获取笔记相关
  const getNote = async (courseId, periodId) => {
    if (courseId && periodId) {
      isClick.value = true
      await getNoteList({ courseId, periodId }).then((res) => {
        // notetype.value = res.rows.length ? 'alt' : 'add'
        if (res.rows.length > 0) {
          console.log(res.rows);
          if (res.rows[0].periodId === periodId) {
              detail.value = res.rows[0]
          }
  
          // res.rows.forEach((item) => {
          //   if (item.periodId === periodId) {
          //     detail.value = item
          //   }
          // })
        } else {
          var { chapterName } = newChapter.value
          var { courseId, chapterId, id, periodName } = newHour.value
          var { courseName } = urlParams
          console.log("课程信息"+urlParams.categoryId)
          detail.value = {
            courseId,
            courseName,
            chapterId,
            chapterName,
            periodId: id,
            periodName,
            content: '',
            projectId: urlParams.categoryId
          }
        }
      })
    } else {
      isClick.value = false
    }
  }
  // 修改笔记类型
  const addEndType = (res) => {
    getNote(newHour.value.courseId, newHour.value.id)
  }
  
  // 答疑页面
  const getQuiz = (courseId, periodId) => {
    if (courseId && periodId) {
      isClick.value = true
      getQuizList({ courseId, periodId }).then((res) => {
        let id = res.rows[0]?.id || 0
        if (id) {
          getQuizDetail({ proId: id ,sort:1}).then((del) => {
            msgList.value = del.rows
          })
        } else {
          msgList.value = []
        }
      })
    } else {
      isClick.value = false
    }
  }
  // 导航切换
  const switchover = async (t) => {
    if (t.props.label === '笔记') {
      await getNote(newHour.value.courseId, newHour.value.id)
    } else if (t.props.label === '答疑') {
      await getQuiz(newHour.value.courseId, newHour.value.id)
    }
  }
  
  // 用户添加问题
  const useraddQuestion = () => {
    if (!problemValue.value && !isClick.value) return
    var { chapterName } = newChapter.value
    var { courseId, chapterId, id, periodName } = newHour.value
    var { courseName } = urlParams
    var addQueryParams = {
      courseId,
      content: problemValue.value,
      courseName,
      projectId: urlParams.categoryId,
      chapterId,
      chapterName,
      periodId: id,
      periodName,
      duration: videotime.value
    }
    console.log("添加"+addQueryParams)
    addQuestion(addQueryParams).then((res) => {
      if (res.code === 200) {
        addQueryParams.createTime = moment().format('YYYY-MM-DD HH:mm:ss')
        addQueryParams.proType = 1
        addQueryParams.duration = moment
          .duration(addQueryParams.duration, 'seconds')
          .format('HH:mm:ss', { trim: false })
        msgList.value.push(addQueryParams)
        problemValue.value = ''
      }
    })
  }
  
  // 添加学习记录
  const addlog = (periodTime,periodCom) => {
    var { chapterName } = newChapter.value
    var { courseId, chapterId, id, periodName } = newHour.value
    var { courseName } = urlParams
  
    addvideoLog.value = {
      userNo: store.getters.userInfo.userId,
      courseId,
      courseName,
      chapterId,
      chapterName,
      periodId: id,
      periodName,
      periodCom: periodCom,
      duration: player.currentTime,
      periodTime: periodTime
    }
    addCourseLog(addvideoLog.value)
  }
  const percentage = (item) => {
  if(item.study!=null&&item.study){
    return 100
  }else if(item.duration==null||item.duration==0){
    return 0
  }
             return      ((parseFloat(item?.duration) || 0) / (item?.videoLength || 0)) * 100 < 100
                        ? ((parseFloat(item?.duration) || 0) /
                            (item?.videoLength || 0)) *
                          100
                        : 100
  }
  // 切换视频
  const qutVidelUrl = (val) => {
    curriculum.value.forEach((items, indexs) => {
      items.children.forEach((item, index) => {
        if (item.id === val.id) {
          who.value = [indexs, index]
          newChapter.value = items
          newHour.value = item
          if (oldPlayInfo.value.id !== newHour.value.id) {
            oldPlayInfo.value = val
          }
          pageTitle.value = val.periodName
          // 切换视频源
          // player.src  ="http://wxsp.kaifo.com/sv/11678d67-17ee87356bb/11678d67-17ee87356bb.mp4"
          player.src = val.videoUrl
          player.currentTime = val.duration || 0
          player.controls = ''
          player.config.disableProgress = false
          console.log("换课"+val.study);
          clearInterval(showRegTime);
          if(urlParams.brush){
             if(val.study!=null&&!val.study){
                startTime()
                player.config.disableProgress = true
              }
          }
          getNote(val.courseId, val.id)
          getQuiz(val.courseId, val.id)
        }
      })
    })
  }
  
  const onMounted = () => {
    setTimeout(() => {
      // 初始化视屏播放器
      var isdisable = false;
      var playbackRateval = [1, 1.25, 1.5,1.75, 2];
      console.log("防刷"+urlParams.brush);
      if(urlParams.brush){
        if(newHour.value.study!=null&&!newHour.value.study){
        isdisable =true
          startTime()
        }
      }
      player = new Player({
        id: '#videoPlayer',
        el: document.querySelector('#videoPlayer'),
        playsinline: 10,
        volume: 0.6, // 默认音量
        playbackRate: playbackRateval,
        defaultPlaybackRate: 1,
        controlPlugins: [volume,playbackRate, pip, flex],
        autoplay: true, // 自动播放
        cssFullscreen: true, // 全屏
        disableProgress: isdisable, // 禁用进度条
        fitVideoSize: 'auto', // 布局
        pip: true,
        url: newHour.value.videoUrl,
        screenShot: true,
        // lastPlayTime: 70,// newHour.value.duration
        lastPlayTimeHideDelay: 0, //提示文字展示时长（单位：秒）
        // controls: false
        // ignores: ['progress']
         // 添加以下配置项进行性能优化
        // 设置缓冲区大小
        preloadTime: 30,
        // 开启优化模式
        optimize: 'quality',
        // 设置视频质量
        defaultQuality: 'sd',
        // 设置最大缓冲区大小
        maxBufferSize: 1024 * 1024 * 100,
        // 设置最大缓冲区时长
        maxBufferedTime: 30
      })
      // player.config.disableProgress = true
      console.log("当前播放+"+newHour.value.videoUrl);
      player.currentTime = newHour.value.duration || 0
      var timer
      // 视频方法
      player.on('play', function () {
        if (newHour.value.id !== oldPlayInfo.value.id) {
          // 如果新的和旧的不相同说明切换了视频
          addvideoLog.value.periodCash = 1 // 观看次数加1
        }
        oldPlayInfo.value = newHour.value
        addlog(0,0);
        timer = setInterval(() => {
          if(player&&!player.paused){
          addlog(pageOpenTime.value,0)
          }
        }, pageOpenTime.value * 60 * 1000)
      })
      // 视频方法
      // player.once('destroy',()=>{console.log('destroy')})
      player.on('pause', function () {
        // 视频暂停
        clearInterval(timer)
      })
      player.on('timeupdate', function (e) {
        // 视频播放进度变化
        videotime.value = player.currentTime
        console.log("curriculum+"+who.value[0]+who.value[1]);
        curriculum.value[who.value[0]].children[who.value[1]].duration = player.currentTime
        isloveend =
          player.video.duration === player.currentTime
            ? 1
            : player.video.duration - player.currentTime < pageOpenTime.value * 60
            ? 1
            : 0
        if (isloveend) {
          addvideoLog.value.periodCom = isloveend
        }
      })
      player.on('ended', function () {
        player.config.disableProgress = false
        console.log(curriculum.value[who.value[0]].children[who.value[1]]);
        curriculum.value[who.value[0]].children[who.value[1]].study=true
        // 视频播放完毕
        addvideoLog.value.periodCom = 1
        clearInterval(timer)
        clearInterval(showRegTime);
        addlog(pageOpenTime.value,1)
      })
    }, 100)
  }
  // 返回页面
  const goback = () => {
    // router.go(-1) || router.push('/user/myCourses')
    router.push('/user/myCourses')
  }
  var showRegTime
  const showReg = (ti) => {
          console.log("几秒后验证"+ti);
    if(ti){
    showRegTime = setTimeout(() => {
      player.pause()
      player.exitFullscreen()
      regImg.value.open()
          addlog(0,0);
    }, ti*1000)
    }
  }
  const closeReg = (v) => {
    startTime();
    player.play()
  }
  var regTime
  const startTime = () => {
    regTime = setTimeout(() => {
        var time1 =  player.duration*3/10
        var time2 =  player.duration*6/10
        if(player.currentTime<time1){
              showReg(time1-player.currentTime);
        }else if(player.currentTime<time2){
                showReg(time2-player.currentTime);
        }
    }, 2000)
  }
  onBeforeUnmount(() => {
    clearInterval(regTime)
    clearInterval(showRegTime);
  })
  // 监听标题切换，动态切换页面标题
  watch(
    () => pageTitle.value,
    (newVal) => {
      document.title = newVal
    },
    {
      immediate: true
    }
  )
  </script>
  
  <style lang="scss" scoped>
  @import '@/common/rem';
  @import '@/styles/mixin';
  #videoPlayer {
    width: 100% !important;
    height: calc(100% - 80px) !important;
  }
  .learnVideo {
    width: 100%;
    height: 100vh;
    .el-aside {
      background: #353538;
      ::v-deep div {
        border: none !important;
      }
      ::v-deep .el-tabs__nav {
        width: 100%;
        height: rem(80);
        line-height: rem(80);
        background: #2b2b2b;
        .el-tabs__item {
          padding: 0;
          line-height: 4;
          width: 33.3%;
          height: rem(80);
          font-size: 22px;
          font-weight: bold;
          text-align: center;
          color: #ffffff;
        }
        .is-active {
          background: #353538;
        }
      }
      ::v-deep .el-collapse-item__header {
        width: rem(360);
        margin: 0 auto !important;
        background: #f3f5f7;
        border-radius: rem(8);
        padding-left: rem(20);
      }
      ::v-deep .el-collapse-item__content {
        font-weight: 400;
        font-size: rem(16);
        color: #ffffff;
        background: #353538;
        padding-left: 2em;
      }
      .el-collapse-item {
        margin-top: rem(10);
      }
      .answerQuestions {
        margin: 0 20px;
        margin-top: 20px;
        line-height: 1.2rem;
        overflow-y: auto;
        position: relative;
        height: 490px;
        border-radius: 5px 5px 0 0;
        background: #ffffff;
        .showMessage {
          padding: 20px;
          > div {
            border-bottom: 1px solid #dddddd7a !important;
          }
        }
      }
      .problemInput {
        height: 88px;
        // width: calc(400px - 40px);
        border: 0 0 5px 5px;
      }
      .button {
        font-size: 1.5rem;
        margin: 20px;
        color: #ffffff;
        font-weight: bold;
        line-height: 48px;
        background: #6e7eb8;
        border-radius: 5px;
        text-align: center;
        margin-top: 25px;
      }
      .section {
        padding-top: rem(10);
        align-items: center;
      }
      .sectionTitle {
        margin-left: rem(20);
        width: 65%;
      }
    }
    .el-main {
      padding: 0;
      margin: 0;
      min-width: 800px;
      overflow: hidden;
      .el-header {
            --el-header-height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        background: #2b2b2b;
        div {
          margin: auto 0;
        }
        .back {
          width: rem(180);
          height: rem(50);
          font-weight: bold;
          line-height: rem(50);
          text-align: center;
          background: #353538;
        }
        .title {
          color: #ffffff;
          margin: auto;
        }
      }
    }
  }
  .subtitle{
    font-size: 14px;
  }
  </style>
  