<template>
  <div class="editor-container" >
    <div id="editor-box" style="height: 500px;"></div>
    <div class="button" @click="onSubmitClick" style="margin-top: 98px;">保存</div>
  </div>
</template>

<script setup>
import E from 'wangeditor'
import { onMounted, defineProps, defineEmits, watch } from 'vue'
import { ElMessage } from 'element-plus'
import { addNote, alterNote } from '../../../../api/user'
import { numBer } from '../../../../common/regexp'
const emits = defineEmits(['addEnd'])
const props = defineProps({
  title: {
    required: true,
    type: String
  },
  detail: {
    type: Object
  },
  time: {
    type: [String, Number]
  },
  notetype: {
    type: [String, Number]
  },
  isClick: {
    type: Boolean,
    default: true
  }
})
// Editor实例
let editor
// 处理离开页面切换语言导致 dom 无法被获取
let el
onMounted(() => {
  el = document.querySelector('#editor-box')
  initEditor()
})
const initEditor = () => {
  editor = new E(el)
  editor.config.zIndex = 1
  editor.config.height = '500px'
  // 菜单栏提示
    editor.config.menus = ['bold','fontSize','fontName','italic','underline','strikeThrough','indent',
    'foreColor','backColor','justify','splitLine','undo','redo',]
  editor.config.showMenuTooltips = true
  editor.config.menuTooltipPosition = 'down'
  editor.create()
}
// 编辑相关
watch(
  () => props.detail,
  (val) => {
    editor.txt.html(val.content)
  }
)
watch(
  () => props.time,
  (val) => {}
)
const onSubmitClick = () => {
  if (props.notetype === 'add' && props.isClick) {
    addNote({
      ...props.detail,
      duration: props.time,
      content: editor.txt.html()
    }).then((res) => {
      if (res.code === 200) {
        emits('addEnd', { ...props.detail })
        ElMessage({
          message: '添加成功',
          type: 'success'
        })
      }
    })
  } else if (props.notetype === 'alt' && props.isClick) {
    alterNote({
      ...props.detail,
      duration: props.time,
      content: editor.txt.html()
    }).then((res) => {
      if (res.code === 200) {
        ElMessage({
          message: '修改成功',
          type: 'success'
        })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.editor-container {
  color: #ffffff;
  font-size: 1rem;
  #editor-box {
    color: #000;
  }
  .button {
    font-size: 1.5rem;
    margin-top: 20px;
    font-weight: bold;
    line-height: 48px;
    background: #6e7eb8;
    border-radius: 5px;
    text-align: center;
  }
}
</style>
